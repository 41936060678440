import * as React from 'react';

import Layout from '../containers/Layout/Layout';
import {
  RegistrationWrapper,
  Users,
  ContactForm,
} from '../containers/Registration';
import { Container, Seo, Alert, Margins } from '../components';
import { useTranslation } from '../hooks';

const Registration = ({ pageContext: { currentPath } }) => {
  const { t, te, locale } = useTranslation();
  const [selectedData, setSelectedData] = React.useState({
    userId: '',
    userName: '',
    date: '',
    time: '',
  });

  return (
    <Layout>
      <Seo
        title={t('SEO.REGISTRATION.TITLE')}
        location={currentPath}
        lang={locale}
        importReCaptcha
      />
      <Container>
        <Margins size="3x">
          <RegistrationWrapper>
            <Users setSelectedData={setSelectedData} />
            <div id="contact-form">
              {selectedData.userId !== '' && (
                <ContactForm selectedData={selectedData} />
              )}
            </div>
          </RegistrationWrapper>
          {te('REGISTRATION.INFO') && (
            <Alert>
              <p className="text-small">{t('REGISTRATION.INFO')}</p>
            </Alert>
          )}
        </Margins>
      </Container>
    </Layout>
  );
};

export default Registration;
